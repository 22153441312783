#chat_wrap form { 
  @apply fixed bottom-0 left-0 w-screen flex flex-row bg-gray-600 dark:text-gray-900 p-1
}
#chat_wrap .sendName{
  @apply border-gray-300 text-gray-900 rounded-lg w-20 text-center
}
#chat_wrap .sendMsg {
  @apply border-gray-300 text-gray-900 rounded-lg px-2 ml-1 grow
}
#chat_wrap .sendName:focus { outline: none; }
#chat_wrap .sendMsg:focus { outline: none; }
#chat_wrap form > button { 
  @apply font-bold px-4 py-1 rounded text-sm bg-blue-500 text-white ml-1;
}

#chat_wrap .messages { list-style-type: none; margin: 0; padding: 0; }
#chat_wrap .messages > li { padding: 0.5rem 1rem;background: #f5f8ea;border:1px solid green; }