@font-face {
  font-family: "Karla";
  src: url("./font/karla/static/Karla-ExtraLight.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Nanum Pen";
  src: url("./font/nanum_pen_script/NanumPenScript-Regular.ttf");
}
@font-face {
  font-family: "sandbox";
  src: url("./font/sandbox/SB_agro_L.ttf");
}
@font-face {
  font-family: "NotoSansKR";
  src: url("./font/noto_sans_kr/NotoSansKR-Medium.otf");
}
@font-face {
  font-family: "CuteFont";
  src: url("./font/cute_font/CuteFont-Regular.ttf");
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

body{
  font-family: 'Noto Sans KR', sans-serif;
}

body img,body video{
  display: inline-block;
}