.search-input-box{
  @apply flex flex-wrap items-center gap-1 text-sm py-1 text-black dark:text-slate-200 text-base;
}
.search-input{
  @apply text-sm text-black dark:text-slate-200 dark:bg-slate-700 p-1;
  border:1px solid #ccc;
}
.search-input_select{
  @apply pr-5;
  background-position: right -0.1rem center;
}
.dark .search-input_select{
  @apply text-slate-200 bg-slate-700;
}