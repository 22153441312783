.popup-background{
  @apply fixed top-0 left-0 w-full h-screen bg-transparent flex justify-center items-center;
}
.popup-wrap{
  @apply relative bg-gray-100 dark:bg-gray-800 border-2 border-slate-300 dark:border-slate-800 border-t-0;
  max-width: 100vw;
  max-height: 100vh;
}
.popup-title-bar{
  @apply absolute top-0 left-0 w-full overflow-hidden bg-slate-300 dark:bg-stone-800;
  height: 32px;
  z-index: 10;
}
.popup-title-close-btn{
  @apply absolute right-0 top-0 font-bold text-sm text-center bg-yellow-200 text-gray-700 dark:bg-stone-900 dark:text-gray-200;
  height: 32px;
  width: 32px;
  line-height: 32px;
}
.popup-title-close-btn:hover{
  @apply text-amber-800 dark:bg-orange-700 dark:text-amber-200;
}
.popup-content-div{
  @apply text-lg font-thin bg-white dark:text-white dark:bg-gray-900 w-full h-full overflow-auto p-2;
  padding-top:32px;
  padding-bottom: 300px;
}