@tailwind base;
@tailwind components;
@tailwind utilities;

@import './css/base.css';
@import './css/btn.css';
@import './css/layout.css';
@import './css/nav.css';
@import './css/content.css';
@import './css/write.css';
@import './css/react_datepicker.css';
@import './css/popup.css';
@import './css/header.css';
@import './css/paging.css';
@import './css/aggrid.css';
@import './css/list.css';
@import './css/search.css';
@import './css/reply.css';