.chat_wrap{
  width:100%;
  height: 70vh;
  position: relative;
}
.chat_div{
  width: 98%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.connector_show_btn{
  position: absolute;
  right: 8px;
  top:8px;
}
.chat_user_list_wrap{
  position: absolute;
  top: 5px;
  left: 20%;
  width:80%;
  height: 70vh;
  background: #fff;
  border:1px solid #ccc;
}
.dark .chat_user_list_wrap{
  background: rgb(82, 81, 81);
}

.chat_content_div{
  flex-basis: 300px;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.chat_content_div .chat_content_row{
  padding:4px;
}
.chat_content_div .chat_content_row .chat_content_row_msg_wrap{
  display: inline-block;
}
.chat_content_div .chat_content_row .chat_content_row_msg{
  display: inline-block;
  padding: 5px 15px;
  border-radius: 10px;
  background: rgb(99, 99, 99);
  color:#fff;
}
.chat_content_div .chat_content_row_time{
  @apply text-xs;
  color:gray;
  padding:0 2px;
}
.chat_content_div .chat_content_row_unread{
  @apply text-xs text-yellow-500 dark:text-yellow-200;
  padding:0 2px;
}

/* 내메세지 오른쪽 정렬 */
.chat_content_div .chat_content_row.my_msg_row .chat_content_row_msg{
  background: rgb(230, 255, 5);
  color:rgb(0, 0, 0);
}
.chat_content_div .chat_content_row.my_msg_row .chat_content_row_user{
  display: none;
}
.chat_content_div .chat_content_row.my_msg_row{
  text-align: right;
}

.chat_content_div .chat_content_row_date{
  @apply text-sm;
  text-align: center;
}

.chat_send_div{
  display: flex;
  flex-direction: row;
}

.chat_send_div .chat_send_msg_div{
  flex-grow: 1;
}

.chat_send_div .chat_send_msg_div{
  text-align: center;
}
.chat_send_div .chat_send_msg_input{
  width: 98%;
}
.dark .chat_send_div .chat_send_msg_input{
  background: gray;
  color:#fff;
}