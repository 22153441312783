.top-nav-wrap{
  @apply flex justify-center flex-col md:flex-row;
  border-top:5px solid #8e8d07;
  width:100%;
}
.top-nav-item{
  @apply text-black dark:text-white dark:bg-stone-800 px-5 py-2 text-base font-bold;
}
.top-nav-item.active{
  @apply text-black;
  background:#8e8d07;
  color:#fffbfb;
}
.top-nav-item:hover {
  @apply text-white;
  background:#8e8d07;
}


.left-nav-wrap{
  @apply flex flex-col;
}
.left-nav-item{
  @apply text-black dark:text-white pl-7 py-4 text-base font-bold border-b-2 border-inherit text-left;
}
.left-nav-item.active{
  @apply bg-slate-100;
  color:#8e8d07;
}
.left-nav-item:hover{
  @apply bg-slate-100;
  color:#8e8d07;
}