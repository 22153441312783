.list-table-div{
  width: 100%;
  overflow: auto;
}
.list-table-div table{
  width: 100%;
  border-collapse: collapse;
}
.list-table-div .row-col-div{
  display: inline-block;
  white-space: nowrap;
  overflow-x: hidden;
}

.list-table-div thead tr{
  background: #e3e3e3;
}
.dark .list-table-div thead tr{
  background: #101010;
}

.list-table-div tbody tr{
  background: rgb(255, 255, 255);
}
.list-table-div tbody tr:nth-child(even){
  background: #fcfcfc;
}
.dark .list-table-div tbody tr{
  background: #181d1f;
}
.dark .list-table-div tbody tr:nth-child(even){
  background: #222628;
}

.list-table-div tbody tr.active{
  background: #2196f3;
}
.list-table-div tbody tr.active td{
  color:rgb(255, 255, 255);
}
.dark .list-table-div tbody tr.active{
  background: #2196f3;
}

.list-table-div th,.list-table-div td{
  @apply text-sm;
  border: 1px solid rgb(177, 176, 176);
  color:black;
  padding:2px 3px;
}
.dark .list-table-div th, .dark .list-table-div td{
  color:rgb(255, 255, 255);
}

.list-table-div th{
  font-weight: bold;
}
.dark .list-table-div th{
  font-weight: bold;
}