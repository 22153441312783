.btn-s {
  @apply font-bold px-2 py-0 rounded text-xs focus:ring-1;
  white-space:nowrap;
}
.btn {
  @apply font-bold px-4 py-1 rounded text-sm;
  white-space:nowrap;
}
.btn-l {
  @apply font-bold px-7 py-3 rounded text-base;
  white-space:nowrap;
}
.btn-xl {
  @apply font-bold px-7 py-4 rounded text-xl;
  white-space:nowrap;
}

.btn-box-center{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-center
}
.btn-box-left{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-start
}
.btn-box-right{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-end
}

.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}

.btn-yellow {
  @apply text-white bg-yellow-400;
}
.btn-yellow:hover {
  @apply bg-yellow-500;
}

.btn-red {
  @apply text-white bg-red-700;
}
.btn-red:hover {
  @apply bg-red-800;
}

.btn-dark {
  @apply text-white;
  background-color: #5e5e5e;
}
.btn-dark:hover {
  background-color: #4b4b4b;
}

.btn-dark2 {
  @apply text-white;
  background-color: #7f7f7f;
}
.btn-dark2:hover {
  background-color: #696969;
}

.btn-gray {
  @apply bg-gray-200 text-gray-700;
}
.btn-gray:hover {
  @apply bg-gray-300;
}

.btn-brown {
  @apply text-white;
  background:#8e8d07;
}
.btn-brown:hover {
  background:#6d6d04;
}

.btn-line-gray{
  @apply text-gray-600 border-gray-200 shadow-md border-2 dark:text-gray-200;
}
.btn-line-gray:hover{
  @apply text-gray-700 border-gray-300 shadow-lg dark:text-gray-300;
}

.btn-round-yellow {
  @apply text-white bg-yellow-400 rounded-full;
}
.btn-round-yellow:hover {
  @apply bg-yellow-500;
}

.btn-round-green {
  @apply text-white bg-green-600 rounded-full;
}
.btn-round-green:hover {
  @apply bg-green-700;
}

.btn-round-green2 {
  @apply text-white bg-green-500 rounded-full;
}
.btn-round-green2:hover {
  @apply bg-green-600;
}

.btn-round-dark {
  @apply text-white rounded-full;
  background-color: #5e5e5e;
}
.btn-round-dark:hover {
  background-color: #4b4b4b;
}

.btn-round-orange {
  @apply text-white bg-orange-500 rounded-full;
}
.btn-round-orange:hover {
  @apply bg-orange-600;
}