.con-title{
  @apply text-2xl text-center text-gray-900 dark:text-gray-200
}

.con-p-gray{
  @apply text-lg font-thin text-gray-600 dark:text-white dark:text-gray-200;
}

.con-span-black{
  @apply font-thin text-gray-900 dark:text-white;
}

.view-table-div{
  @apply w-full relative sm:rounded-lg;
}
.view-table-div table{
  @apply w-full text-left border-collapse;
}
.view-table-div tr{
  
}
.view-table-div th, .view-table-div td{
  @apply py-1 px-2 text-base border border-gray-300 dark:border-slate-700;
}
.view-table-div th{
  @apply bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200;
  min-width: 100px;
}
.view-table-div td{
  @apply bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-400;
  min-width: 120px;
}

.left-right-wrap{
  @apply flex flex-col lg:flex-row space-x-1;
}
.left-right-wrap div:nth-child(1) { flex-grow: 1; }
.left-right-wrap div:nth-child(2) { flex-grow: 2; }