.write-table-div{
  @apply w-full relative shadow-md sm:rounded-lg;
}
.write-table-div table{
  @apply w-full text-left text-gray-500 dark:text-gray-400 border-collapse;
}
.write-table-div tr{
  @apply border-b border-gray-200 dark:border-gray-600;
}
.write-table-div th{
  @apply py-3 px-6 text-sm bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200;
  min-width: 100px;
}
.write-table-div td{
  @apply px-2 text-sm dark:bg-gray-800 dark:text-gray-400;
  min-width: 120px;
}

.write-table-div .row-input{
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg 
  focus:ring-blue-500 
  focus:border-blue-500 
  w-full p-1 
  dark:bg-gray-700 
  dark:border-gray-600 
  dark:placeholder-gray-400 
  dark:text-white 
  dark:focus:ring-blue-500 
  dark:focus:border-blue-500;
}