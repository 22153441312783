
.header-wrap{
  @apply content-center sm:flex-row relative;
  width:100%;
  min-height:50px;
}
.header-wrap-top-wrap{
  display: flex;
  justify-content: center;
  width:100%;
  min-height:50px;
  border-bottom: 1px solid #DCDCDC;
}
.dark .header-wrap-top-wrap{
  border-bottom: 1px solid #68686e;
}

.header-wrap-top{
  @apply flex justify-between content-center flex-col sm:flex-row relative max-w-5xl;
  width:100%;
}
.header-wrap-sub-wrap{
  display: flex;
  justify-content: center;
  width:100%;
  border-bottom: 1px solid #DCDCDC;
  padding:4px;
  padding-bottom: 6px;
}
.dark .header-wrap-sub-wrap{
  border-bottom: 1px solid #68686e;
}
.header-wrap-sub{
  @apply content-center relative max-w-5xl;
  width:100%;
}

.header-item{
  @apply text-center text-base;
}
.header-item:nth-child(1){
  @apply text-left sm:text-center;
}
.header-item-hidden{
  @apply hidden mt-2 sm:block sm:mt-0;
}
.header-item-hidden.active{
  @apply block sm:block;
}

.header-menu-img{
  @apply absolute top-2 right-2 text-black block sm:hidden dark:fill-gray-50;
}

.header-item-right-login{
  padding-top: 10px;
  color:#383838;
}
.dark .header-item-right-login{
  color:#a8a8a8;
}

.header-nav-wrap{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-center pr-11 sm:pr-0;
  padding-top: 10px;
}
.header-nav-item{
  @apply px-3 font-black text-lg;
  color:#383838;
}
.dark .header-nav-item{
  color:#a8a8a8;
}
.header-nav-item.active{
  @apply text-black;
  color:#00846C;
}
.header-nav-item:hover {
  color:#00846C;
}

.header-wrap-sub{
  @apply flex flex-col;
}
.header-wrap-sub .sub-menu-split-line{
  color:#383838;
}
.header-wrap-sub .sub-menu-split-line:last-child{
  display: none;
}
.sub-menu-btn{
  @apply px-3 font-bold text-sm;
  color:#383838;
}
.sub-menu-btn:hover{
  color:#00846C;
}

.dark .sub-menu-btn{
  color:#a8a8a8;
}
.sub-menu-btn:first-child{
  @apply pl-0;
}
.sub-menu-btn:last-child{
  @apply pr-0;
}
.sub-menu-btn.active{
  color:#00846C;
}