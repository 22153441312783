.ag-header-cell{
  padding-left: 10px;
}
.ag-header-cell:nth-child(1) {
  padding-left: 10px;
}
.ag-cell:nth-child(1) {
  padding-left: 10px;
}

.ag-row.ag-row-green{
  background-color:rgb(0, 151, 0);
  color:rgb(255, 255, 255);
}
.ag-row.ag-row-blue{
  background-color:rgb(66, 0, 232);
  color:rgb(255, 255, 255);
}
.ag-row.ag-row-red{
  background-color:rgb(255, 79, 79);
  color:rgb(255, 255, 255);
}
.ag-row.ag-row-yellow{
  background-color:rgb(230, 255, 6);
  color:rgb(0, 0, 0);
}