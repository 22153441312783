.main_body{
  @apply absolute h-screen w-full overflow-auto bg-white dark:bg-black;
}
.main_body_box{
  @apply shadow-md min-w-min min-h-full;
}

.content-wrap{
  @apply flex flex-col md:flex-row;
  min-height:700px;
  min-width: 640px;
}
.content-left-wrap{
  @apply basis-56 min-h-full border-t-2 border-r-2 border-inherit shadow-md;
}
.content-center-wrap{
  @apply basis-full bg-slate-100 dark:bg-slate-800 p-2 min-h-full;
}


.main-content-wrap{
  display: flex;
  justify-content: center;
  width:100%;
}
.main-content-div{
  @apply content-center relative max-w-5xl;
  width:100%;
}