.select_title{
  position: relative;
  cursor: pointer;
  line-height: 25px;
  text-align: center;
  padding: 0px 5px;
  border:1px solid #ddd;
  min-width: 70px;
  max-width: 100px;
}
.select_title_icon{
  position: absolute;
  right: 1px;
  top:10px;
  display: inline-block;
  background: #484848;
  color:#fff;
  text-align: center;
  width: 10px;
  height: 15px;
  line-height: 15px;
  font-size: 8px;
  border-radius: 3px;
}
.select_title_close{
  position:absolute;
  right:1px;
  top:10px;
  height:16px;
  line-height: 15px;
  font-size: 9px;
}
.select_wrap{
  position: absolute;
  min-width: 80px;
  background: #fff;
  color:#000;
  padding:3px;
  border-radius: 3px;
  z-index: 10;
  border:1px solid #ddd;
}
.select_wrap_con{
  position: relative;
}
.select_row_box{
  line-height: 21px;
  padding:2px;
  border-top: 1px  solid #ddd;
  cursor: pointer;
  white-space : nowrap;
}
.select_row_box:hover{
  background: #d4dcff;
}
.select_row_box.active{
  background: #379D89;
  color:#fff;
}